import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { SocialIcon } from 'react-social-icons';
import { Navigation } from '.';

// Styles
// import '../../styles/app.css';
import '../../styles/index.less';
import Logo from './Logo';
import CookieConsent from 'react-cookie-consent';

/**
 * Main layout component
 *
 * The Layout component wraps around each page and template.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 *
 */
const DefaultLayout = ({ data, children, bodyClass, isHome }) => {
  const site = data.allGhostSettings.edges[0].node;

  return (
    <>
      <Helmet>
        <html lang={site.lang} />
        <style type="text/css">{`${site.codeinjection_styles}`}</style>
        <meta
          name="google-site-verification"
          content="oGyEwJeUcy2EI58TrO1ePpEe_1vuGFn3MCSC-AX2rhg"
        />
        <body className={bodyClass} />
      </Helmet>

      <div className="viewport">
        <div className="viewport-top">
          {/* The main header section on top of the screen */}
          <header
            className="site-head"
            style={{
              ...(site.cover_image && {
                backgroundImage: `url(${site.cover_image})`,
              }),
            }}
          >
            <div className="container">
              <div className="site-banner">
                <Logo />
              </div>
              <nav className="site-nav">
                <Navigation data={site.navigation} navClass="site-nav-item" />
              </nav>
            </div>
          </header>
        </div>
        <main className="site-main">
          {/* All the main content gets inserted here, index.js, post.js */}
          {children}
        </main>

        <CookieConsent
          location="bottom"
          buttonText="Akceptuję"
          // style={{
          //   background: '#fff',
          //   color: '#333',
          //   fontFamily: '"Roboto", sans-serif',
          //   fontWeight: 300,
          //   fontSize: '14px',
          // }}
          // buttonStyle={{
          //   background: 'rgba(242, 242, 242)',
          //   color: 'hsla(0,0%,0%,0.73)',
          //   border: '2px solid rgba(0,0,0,0.73)',
          //   fontSize: '14px',
          // }}
          expires={150}
        >
          Ta strona wykorzystuje pliki cookies. Dalsze korzystanie ze strony
          oznacza, że zgadzasz się na ich użycie.
        </CookieConsent>

        {/* <div className="viewport-bottom">
          <footer className="site-foot">
            <div className="container">
              <div className="footer-socials">
                <SocialIcon
                  url="https://www.instagram.com/kamilwysockifotografia/"
                  style={{ height: 24, width: 24 }}
                  fgColor="#777"
                  bgColor="transparent"
                />
              </div>
              <span>www.kamilwysocki.com</span>
            </div>
          </footer>
        </div> */}
      </div>
    </>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  bodyClass: PropTypes.string,
  isHome: PropTypes.bool,
  data: PropTypes.shape({
    file: PropTypes.object,
    allGhostSettings: PropTypes.object.isRequired,
  }).isRequired,
};

const DefaultLayoutSettingsQuery = (props) => (
  <StaticQuery
    query={graphql`
      query GhostSettings {
        allGhostSettings {
          edges {
            node {
              ...GhostSettingsFields
            }
          }
        }
        file(relativePath: { eq: "ghost-icon.png" }) {
          childImageSharp {
            fixed(width: 30, height: 30) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={(data) => <DefaultLayout data={data} {...props} />}
  />
);

export default DefaultLayoutSettingsQuery;
