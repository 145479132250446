import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import moment from 'moment';

const PostCard = ({ post }) => {
  const url = `blog/${post.slug}/`;
  const date = moment(post.published_at).format('DD.MM.YYYY');

  return (
    <Link to={url} className={`page-card ${post.feature_image ? '' : 'no-image'}`}>
        <div
          className="page-card-image"
          style={{
            backgroundImage: `url(${post.feature_image})`,
          }}
        />
        <div className="page-card-description">
          <p className="label">{date}</p>
          <h2 className="page-card-title">{post.title}</h2>
        </div>
    </Link>
  );
};

PostCard.propTypes = {
  post: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    feature_image: PropTypes.string,
    featured: PropTypes.bool,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
    excerpt: PropTypes.string.isRequired,
    primary_author: PropTypes.shape({
      name: PropTypes.string.isRequired,
      profile_image: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default PostCard;
