import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const isActive = (navClass) => ((props) =>  {
  const { isCurrent, isPartiallyCurrent } = props
  return isCurrent || isPartiallyCurrent ? { className: `${navClass} active`} : { className: navClass }
})

const Navigation = ({ data, navClass }) => (
    <>
        <Link className={navClass} activeClassName="active" to="/" key="nav-link-id">start</Link>
        <Link className={navClass} getProps={isActive(navClass)} to="/zdjecia" key="nav-link-zdjecia">zdjęcia</Link>
        <Link className={navClass} getProps={isActive(navClass)} to="/blog" key="nav-link-blog">blog</Link>
        <Link className={navClass} getProps={isActive(navClass)} to="/kontakt" key="nav-link-kontakt">kontakt</Link>
    </>
)

Navigation.defaultProps = {
    navClass: `site-nav-item`,
}

Navigation.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    navClass: PropTypes.string,
}

export default Navigation
